export const translation = {
  title: 'Szegedi Református Egyetemi Gyülekezet',
  description:
    'A Szegedi Református Egyetemi Gyülekezet hosszú évek óta biztosít találkozási helyet a legkülönbözőbb beállítottságú, stílusú és gondolkodású emberek számára. E csapat nyitott és befogadó, ezért őszintén érdeklődik az után is, hogy Te ki vagy.',
  hamburgermenu: 'Menü kinyitása',
  pages: {
    home: 'Kezdőlap',
    aboutus: 'Kik vagyunk',
    events: 'Események',
    event: 'Esemény',
    worships: 'Istentiszteletek',
    worship: 'Istentisztelet',
    blog: 'Blog',
    universities: 'Egyetemi tippek',
    movieBlog: 'Hitem a kameralencsén át',
    simpleBlog: 'Részem a Nagy történetben',
    impress: 'Impresszum',
    notfound: 'Az oldal nem található',
    cookie: 'Sütik (cookiek)',
    contact: 'Kapcsolat',
    bible: 'Ige',
  },
  home: {
    welcome: {
      row1: 'Isten hozott',
      row2: 'itthon!',
    },
    subtitle: {
      aboutus: 'Kik vagyunk?',
      nextEvent: 'Még nem késő elindulnod!',
      event: 'Események',
      worship: 'Istentisztelet',
      blog: 'Blog',
    },
    values: {
      row1: 'nyitottság',
      row2: 'együttérzés',
      row3: 'odaadás',
      row4: 'közösség',
      row5: 'növekedés',
      row6: 'megosztás',
      row7: 'egység',
    },
    introduction: 'Olyan keresztény közösség, akik sokaknak szeretnénk befogadó otthona lenni. Olyan közösséggé formálódunk, ahol támogatjuk egymást a személyes növekedésben és a mások felé való szolgálatban.',
    blogs: {
      row1: 'A Blogunk célja az, amit gyülekezeti keresztünk utolsó értékével is kifejezünk: jelen legyünk a másik ember életében.',
      row2: 'Ez azt is jelenti, hogy megosztjuk tapasztalatainkat, élményeinket, hogy így bátorítsuk egymást a keresztény életben.',
      row3: 'Válogass kedvedre a két külön fejezetben megosztott történetek között',
    },
    event: {
      row1: 'Hoppá, úgy tűnik, éppen nem tervezünk semmilyen eseményt. Ennek valószínűleg az lehet az oka, hogy éppen vizsgaidőszak van, vagy nyár. Ne aggódj, ez nem jelenti, hogy nem történik semmi!',
      row2: 'Gyülekezeti életünk, eseményeink elsősorban szorgalmi időszakra koncentrálódik. Addig is, míg ilyen időszak jön újra, nézz szét a Blogon, vagy nézd vissza korábban milyen alkalmakon vettünk részt együtt:',
    },
    worship: {
      row1: 'Alapozás. Na persze nem az a fajta, amire először gondol az ember. Mert sem építkezési előkészület, sem alkoholhoz nem túl sok közünk lesz a félévben. Vagyis építkezés éppen zajlik: sok szorgos munkás dolgozik azon, hogy következő félévtől egy új helyen, új épületben tartsuk alkalmainkat. Persze borhoz is lesz közünk – ha más nem úrvacsorák alkalmából. De mégse erre gondolok elsősorban.',
      row2: 'Arra az alapra, amit Krisztustól kaptunk.',
      row3: 'Mt 28,18-20',
      row4: '„Nekem adatott minden hatalom mennyen és földön. Menjetek el tehát, tegyetek tanítvánnyá minden népet, megkeresztelve őket az Atyának, a Fiúnak és a Szentléleknek nevében, tanítva őket, hogy megtartsák mindazt, amit én parancsoltam nektek; és íme, én veletek vagyok minden napon a világ végezetéig.”',
      row5: 'Erről az igéről, és az ebben megmutatkozó célokról fogunk beszélni ebben a félévben.'
    },
    quote: {
      blog: {
        title:
          '„Vassal formálják a vasat, és egyik ember formálja a másikat.‟',
        subtitle: 'Plbesz 27,17',
      },
      event: {
        title:
          '„Mert ahol ketten vagy hárman összegyűlnek az én nevemben, ott vagyok közöttük.‟',
        subtitle: 'Mt 18,20',
      },
      worship: {
        title: '„Dícsérjétek az Urat!‟',
        subtitle: 'Zsolt 150,1',
      },
    },
    labels: {
      thisWeek: 'Ez a 7',
      prevWeek: 'Múlt 7',
    },
    links: {
      details: 'részletek',
    },
  },
  community: {
    facebook: 'Facebook',
    instagram: 'Instagram',
    anchor: 'Anchor',
    spotify: 'Spotify',
    youTube: 'YouTube',
    deezer: 'Deezer',
    website: 'Weboldal',
    googlemap: 'Térkép'
  },
  impress: {
    operator: 'Üzemeltető',
    universityPastor: 'Egyetemi Lelkész',
    designer: 'Dizájner',
    developer: 'Fejlesztő',
  },
  cookie: {
    introduction:
      'A honlapunk alján megjelenő figyelmeztetés tájékoztat, hogy az oldal sütiket használ, valamint, hogy az oldal használata során ezzel egyetértesz és elfogadod. Az üzenet addig jelenik meg, amíg rá nem kattintasz az "elfogadom" gombra.',
    heading: {
      heading1: 'Miért van szükség a sütikre?',
      heading2: 'Mire használjuk a sütiket?',
      heading3: 'Mire nem használjuk a sütiket?',
      heading4: 'Amennyiben elfogadod a sütik használatát:',
      heading5: 'A sütik kikapcsolása/törlése',
    },
    paragraph: {
      paragraph1:
        'A weboldalak többségéhez hasonlóan mi is használunk sütiket, hogy számodra minél jobb élményt biztosíthassunk. A süti egy kisméretű egyedi szövegfájl, ami akkor kerül a számítógépedre vagy a telefonodra, amikor meglátogatod a webhelyünket.',
      paragraph2:
        'A sütik segítenek abban, hogy az oldal úgy működjön, ahogy neked a legkényelmesebb a használata, megjegyzi az egyéni beállításokat, lehetővé teszi a közösségi oldalakon való megosztást...stb',
      paragraph3:
        'Bármilyen azonosításra alkalmas személyes információ begyűjtésére, bármilyen típusú személyes vagy kényes információ begyűjtésére, adattovábbításra reklám-szolgáltatók felé. Harmadik fél által üzemeltetett weboldalakkal, illetve külső adatszállítókkal nem cserélünk sütiket',
      paragraph4:
        'Ha böngésződ úgy van beállítva, hogy elfogadod a sütiket és továbbra is böngészed oldalunkat, akkor úgy tekintjük, hogy elfogadtad a süti-használatról szóló feltételeinket. Amennyiben úgy döntesz, hogy eltávolítod vagy kikapcsolod a sütiket, elképzelhető, hogy az oldal nem fog megfelelően működni.',
      paragraph5:
        'A sütiket a böngésző "Beállítások" menüjében tudod kikapcsolni. (Ezzel viszont korlátozod az oldalunk és sok más weboldal működését is)',
    },
  },
  footer: {
    title: 'Keress minket!',
    copyright: 'Copyright © 2022 Szegedi Református Egyetemi Gyülekezet',
  },
  links: {
    aboutus: 'tudj meg többet rólunk',
    blog: 'blogok',
    events: 'eseményeink',
    moreevents: 'további eseményeink',
    moredetails: 'Részletekért kattints ide',
    worships: 'Istentiszteleteink',
    more: 'tovább',
    worshipEvents: 'alkalmaink',
    getToKnowUs: 'Ismerj meg minket'
  },
  date: {
    long: '{date, time, ::yyyy.MM.dd} {date, time, ::HH:mm}',
  },
  warning: {
    soom: 'Hamarosan...',
    bible: {
      failedLoadPoems: 'Sikertelen a versek megjelenítése!',
    },
  },
  calendar: {
    button: {
      prevMonth: 'Előző',
      nextMonth: 'Következő',
      details: 'Részletek',
    },
  },
  carousel: {
    prev: 'Előző',
    next: 'Következő',
    moreEvents: 'További eseményeink',
  },
  card: {
    placeholder: '{name} kép',
  },
  event: {
    time: 'Időpont',
    location: 'Helyszín',
    type: {
      worship: 'Istentisztelet',
      online: 'Online',
    },
    connect: {
      facebook: {
        text: 'Csatlakozz az eseményhez {link}-on is',
        placeholder: 'Facebook',
      },
      zoom: {
        text: '{link} videóhívás',
        id: '(azonosító: {id})',
        placeholder: 'Zoom',
      },
      facebookPage: {
        text: 'Látogasd meg az esemény {link} oldalát is',
        placeholder: 'Facebook',
      },
      website: {
        text: 'Látogasd meg az esemény {link}-át oldalát is',
        placeholder: 'weboldal',
      },
      audio: {
        text: 'Hallgasd meg Istentiszteletünket: ',
        youtube: 'Youtube',
        anchor: 'Anchor',
        spotify: 'Spotify',
        deezer: 'Deezer',
      },
    },
    songs: 'Énekek:'
  },
  blogs: {
    action: {
      prev: 'Előző',
      next: 'Következő',
    },
    label: {
      navText: 'További bejegyzések',
    },
  },
  blog: {
    type: {
      all: 'Összes',
      movie: 'Hitem a kameralencsén át',
      simple: 'Részem a Nagy történetben',
    },
  },
  location: {
    text: {
      honvedter: 'Honvéd téri Református templom',
      kalvinter: 'Kálvin téri Református templom',
      babtistaimahaz: 'Baptista Imaház',
      petofitelepireformatustemplom: 'Petőfitelepi Református templom',
      evangelikustemplom: 'Evangélikus templom',
      milka: 'Millenniumi Kávéház és Klub',
      szte: 'SZTE József Attila Tanulmányi és Információs Központ',
      keresztpont: 'Keresztpont Gyülekezeti Központ',
      trinitycafe: 'Trinity Kávézó',
      csiki: 'Csíki Söröző',
    },
    address: {
      honvedter: 'Szeged, Tisza Lajos krt. 91.',
      kalvinter: 'Szeged, Tisza Lajos krt. 35.',
      babtistaimahaz: 'Szeged, Hétvezér u. 5.',
      petofitelepireformatustemplom: 'Szeged, Sirály u. 16.',
      evangelikustemplom: 'Szeged, Tisza Lajos krt. 16.',
      milka: 'Szeged, Dugonics tér 12.',
      szte: 'Szeged, Ady tér 10.',
      keresztpont: 'Szeged, Szentháromság utca 26.',
      trinitycafe: 'Szeged, Szentháromság utca 26.',
      csiki: 'Szeged, Dugonics tér 11-12.',
    },
    label: 'cím',
  },
  restaurants: {
    filter: 'szűrők',
    categories: {
      restaurant: 'étterem',
      confectionery: 'cukrászda',
      cafe: 'kávézó'
    }
  },
  bible: {
    OldTestament: 'Ószövetség',
    NewTestament: 'Újszövetség',
    book: {
      '1Moz': {
        long: 'Mózes első könyve',
        short: '1Móz',
      },
      '2Moz': {
        long: 'Mózes második könyve',
        short: '2Móz',
      },
      '3Moz': {
        long: 'Mózes harmadik könyve',
        short: '3Móz',
      },
      '4Moz': {
        long: 'Mózes negyedik könyve',
        short: '4Móz',
      },
      '5Moz': {
        long: 'Mózes ötödik könyve',
        short: '5Móz',
      },
      Jozs: {
        long: 'Józsué könyve',
        short: 'Józs',
      },
      Bir: {
        long: 'A bírák könyve',
        short: 'Bír',
      },
      Ruth: {
        long: 'Ruth könyve',
        short: 'Ruth',
      },
      '1Sam': {
        long: 'Sámuel első könyve',
        short: '1Sám',
      },
      '2Sam': {
        long: 'Sámuel második könyve',
        short: '2Sám',
      },
      '1Kir': {
        long: 'A királyok első könyve',
        short: '1Kir',
      },
      '2Kir': {
        long: 'A királyok második könyve',
        short: '2Kir',
      },
      '1Kron': {
        long: 'A krónikák első könyve',
        short: '1Krón',
      },
      '2Kron': {
        long: 'A krónikák második könyve',
        short: '2Krón',
      },
      Ezsd: {
        long: 'Ezsdrás könyve',
        short: 'Ezsd',
      },
      Neh: {
        long: 'Nehémiás könyve',
        short: 'Neh',
      },
      Eszt: {
        long: 'Eszter könyve',
        short: 'Eszt',
      },
      Job: {
        long: 'Jób könyve',
        short: 'Jób',
      },
      Zsolt: {
        long: 'A zsoltárok könyve',
        short: 'Zsolt',
      },
      Peld: {
        long: 'A példabeszédek könyve',
        short: 'Péld',
      },
      Pred: {
        long: 'A prédikátor könyve',
        short: 'Préd',
      },
      Enekek: {
        long: 'Énekek éneke',
        short: 'Énekek',
      },
      Ezs: {
        long: 'Ézsaiás próféta könyve',
        short: 'Ézs',
      },
      Jer: {
        long: 'Jeremiás próféta könyve',
        short: 'Jer',
      },
      JSir: {
        long: 'Jeremiás siralmai',
        short: 'JSir',
      },
      Ez: {
        long: 'Ezékiel próféta könyve',
        short: 'Ez',
      },
      Dan: {
        long: 'Dániel próféta könyve',
        short: 'Dán',
      },
      Hos: {
        long: 'Hóseás próféta könyve',
        short: 'Hós',
      },
      Joel: {
        long: 'Jóel próféta könyve',
        short: 'Jóel',
      },
      Am: {
        long: 'Ámósz próféta könyve',
        short: 'Ám',
      },
      Abd: {
        long: 'Abdiás próféta könyve',
        short: 'Abd',
      },
      Jon: {
        long: 'Jónás próféta könyve',
        short: 'Jón',
      },
      Mik: {
        long: 'Mikeás próféta könyve',
        short: 'Mik',
      },
      Nah: {
        long: 'Náhum próféta könyve',
        short: 'Náh',
      },
      hab: {
        long: 'Habakuk próféta könyve',
        short: 'Hab',
      },
      Zof: {
        long: 'Zofóniás próféta könyve',
        short: 'Zof',
      },
      Hag: {
        long: 'Haggeus próféta könyve',
        short: 'Hag',
      },
      Zak: {
        long: 'Zakariás próféta könyve',
        short: 'Zak',
      },
      Mal: {
        long: 'Malakiás próféta könyve',
        short: 'Mal',
      },
      Mt: {
        long: 'Máté evangéliuma',
        short: 'Mt',
      },
      Mk: {
        long: 'Márk evangéliuma',
        short: 'Mk',
      },
      Lk: {
        long: 'Lukács evangéliuma',
        short: 'Lk',
      },
      Jn: {
        long: 'János evangéliuma',
        short: 'Jn',
      },
      ApCsel: {
        long: 'Az apostolok cselekedetei',
        short: 'ApCsel',
      },
      Rom: {
        long: 'Pál levele a rómaiakhoz',
        short: 'Róm',
      },
      '1Kor': {
        long: 'Pál első levele a Korinthusiakhoz',
        short: '1Kor',
      },
      '2Kor': {
        long: 'Pál második levele a Korinthusiakhoz',
        short: '2Kor',
      },
      Gal: {
        long: 'Pál levele a galatákhoz',
        short: 'Gal',
      },
      Ef: {
        long: 'Pál levele az efezusiakhoz',
        short: 'Ef',
      },
      Fil: {
        long: 'Pál levele a filippiekhez',
        short: 'Fil',
      },
      Kol: {
        long: 'Pál levele a Kolosséiakhoz',
        short: 'Kol',
      },
      '1Thessz': {
        long: 'Pál első levele a thesszalonikaiakhoz',
        short: '1Thessz',
      },
      '2Thessz': {
        long: 'Pál második levele a thesszalonikaiakhoz',
        short: '2Thessz',
      },
      '1Tim': {
        long: 'Pál első levele Timóteushoz',
        short: '1Tim',
      },
      '2Tim': {
        long: 'Pál második levele Timóteushoz',
        short: '2Tim',
      },
      Tit: {
        long: 'Pál levele Tituszhoz',
        short: 'Tit',
      },
      Filem: {
        long: 'Pál levele Filemonhoz',
        short: 'Filem',
      },
      Zsid: {
        long: 'A zsidókhoz írt levél',
        short: 'Zsid',
      },
      Jak: {
        long: 'Jakab levele',
        short: 'Jak',
      },
      '1Pt': {
        long: 'Péter első levele',
        short: '1Pt',
      },
      '2Pt': {
        long: 'Péter második levele',
        short: '2Pt',
      },
      '1Jn': {
        long: 'János első levele',
        short: '1Jn',
      },
      '2Jn': {
        long: 'János második levele',
        short: '2Jn',
      },
      '3Jn': {
        long: 'János harmadik levele',
        short: '3Jn',
      },
      Jud: {
        long: 'Júdás levele',
        short: 'Jud',
      },
      Jel: {
        long: 'A Jelenések könyve',
        short: 'Jel',
      },
    },
  },
};
