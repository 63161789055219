import React, { lazy, Suspense } from 'react';
import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Providers } from './context/providers';
import ScrollToTop from './components/pages/common/scroll-to-top/scroll-to-top';
import { translation } from './translations/translations';
import { PAGES } from './datas/pages/pages';
import { Navbar } from './components/navbar/navbar';
import { Footer } from './components/footer/footer';
import { Loading } from './components/common/loading/loading';

const PAGE_CONTACT = lazy(() => import('./components/pages/contact/contact'));
const PAGE_BIBLE = lazy(() => import('./components/pages/bible/bible'));
const PAGE_HOME = lazy(() => import('./components/pages/home/home'));
const PAGE_ABOUTUS = lazy(() => import('./components/pages/aboutus/aboutus'));
const PAGE_EVENTS = lazy(() => import('./components/pages/events/events'));
const PAGE_EVENT = lazy(() => import('./components/pages/event/event'));
const PAGE_WORSHIP = lazy(() => import('./components/pages/worships/worships'));
const PAGE_BLOGS = lazy(() => import('./components/pages/blogs/blogs'));
const PAGE_SIMPLEBLOG = lazy(() => import('./components/pages/blog/simple-blog'));
const PAGE_MOVIEBLOG = lazy(() => import('./components/pages/blog/movie-blog'));
const PAGE_UNIVERSITIES = lazy(() => import('./components/pages/universities/universities'));
const PAGE_GLOSSARYBLOG = lazy(() => import('./components/pages/university/glossaryblog'));
const PAGE_RESTAURANTSBLOG = lazy(() => import('./components/pages/university/restaurantsblog'));
const PAGE_UNIVERSITYBLOG = lazy(() => import('./components/pages/university/universityblog'));
const PAGE_UNIVERSITYSIMPLEBLOG = lazy(() => import('./components/pages/university/university-simple-blog'));
const PAGE_IMPRESS = lazy(() => import('./components/pages/impress/impress'));
const PAGE_NOTFOUND = lazy(() => import('./components/pages/notfound/notfound'));
const PAGE_COOKIE = lazy(() => import('./components/pages/cookie/cookie'));

export default ({ Router, location, context, initialValues }) => {
  return (
    <IntlProvider locale="hu" messages={flatten(translation)}>
      <Router location={location} context={context}>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/* Empty static pages */}
            <Route exact path={PAGES.contact.url}>
              <PAGE_CONTACT />
            </Route>
            <Route exact path={PAGES.bible.url}>
              <PAGE_BIBLE />
            </Route>
            {/* Site pages */}
            <Route>
              <ScrollToTop />
              <Navbar />
              <Providers initialValues={initialValues}>
                <Switch>
                  <Route exact path={PAGES.home.url}>
                    <PAGE_HOME />
                  </Route>
                  <Route exact path={PAGES.aboutus.url}>
                    <PAGE_ABOUTUS />
                  </Route>
                  <Route exact path={PAGES.events.url}>
                    <PAGE_EVENTS />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.event.url}/:${PAGES.event.pathParam}`}
                  >
                    <PAGE_EVENT />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.worship.url}`}
                  >
                    <PAGE_WORSHIP />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.worship.url}/:${PAGES.worship.pathParam}`}
                  >
                    <PAGE_WORSHIP />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.blog.url}/:${PAGES.blog.pathParam}`}
                  >
                    <PAGE_BLOGS />
                  </Route>
                  <Route exact path={PAGES.blog.url}>
                    <PAGE_BLOGS />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.simpleBlog.url}/:${PAGES.event.pathParam}`}
                  >
                    <PAGE_SIMPLEBLOG />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.movieBlog.url}/:${PAGES.event.pathParam}`}
                  >
                    <PAGE_MOVIEBLOG />
                  </Route>
                  <Route exact path={PAGES.universities.url}>
                    <PAGE_UNIVERSITIES />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.glossaryBlog.url}/:${PAGES.glossaryBlog.pathParam}`}
                  >
                    <PAGE_GLOSSARYBLOG />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.restaurantsBlog.url}/:${PAGES.restaurantsBlog.pathParam}`}
                  >
                    <PAGE_RESTAURANTSBLOG />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.universityBlog.url}/:${PAGES.universityBlog.pathParam}`}
                  >
                    <PAGE_UNIVERSITYBLOG />
                  </Route>
                  <Route
                    exact
                    path={`${PAGES.universitySimpleBlog.url}/:${PAGES.universitySimpleBlog.pathParam}`}
                  >
                    <PAGE_UNIVERSITYSIMPLEBLOG />
                  </Route>
                  <Route exact path={PAGES.impress.url}>
                    <PAGE_IMPRESS />
                  </Route>
                  <Route exact path={PAGES.notfound.url}>
                    <PAGE_NOTFOUND />
                  </Route>
                  <Route exact path={PAGES.cookie.url}>
                    <PAGE_COOKIE />
                  </Route>
                  <Redirect to={PAGES.notfound.url} />
                </Switch>
                <Footer />
              </Providers>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </IntlProvider>
  );
};
